svg {
    enable-background: new 0 0 233 74;
    margin-top: 15px;
}

.bombshell-draw {
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    animation: bombshell 2.7s ease-in-out forwards 0.5s;

    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

@keyframes bombshell {
    0% {
        stroke-dashoffset: 3000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}