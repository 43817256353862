.headerlink::before{
    content: '';
    transition: all 0.2s ease-in;
    height: 100%;
    width: auto;
    color: white;
}

.headerlink::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 0;
    left: 0;
    background-color: white;
    transition: all 0.2s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 600;
    overflow-x: hidden;
}

.headerlink:hover::after{
    width: 100%;
}

.link:not(:last-child){
    margin-right: 15px;
}