.swiper {
    width: 100%;
    max-width: 500px;
    margin-top: 100px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.hand{
    animation: antibounce 1s infinite;
    rotate: 90deg;
}

@keyframes antibounce {
    0%,
    100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}